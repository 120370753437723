import React from 'react';
import PropTypes from 'prop-types';

import Row from 'src/components/Row';
import Header from 'src/components/Header/Header';
import Footer from 'src/components/Footer';

import { getDataForLang } from 'src/data';
import { getLanguage } from 'src/translations';

const Layout = ({ children }) => {
  const lang = getLanguage();
  const footer = getDataForLang('footer', lang);

  return (
    <div>
      <Row>
        <Header />
        <div>
          <main>{children}</main>
        </div>
      </Row>
      <Footer links={footer} />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
