export default {
  about: {
    en: 'about us',
    pl: 'o nas',
  },
  contact: {
    en: 'contact',
    pl: 'kontakt',
  },
  more: {
    en: 'more',
    pl: 'więcej',
  },
  aboutAllcon: {
    en: 'About Allcon group',
    pl: 'ALLCON W LICZBACH',
  },
  certs_and_awards: {
    en: 'CERTIFICATES AND AWARDS',
    pl: 'Certyfikaty i nagrody',
  },
  awards_icon_alt: {
    en: 'awards icon',
    pl: 'ikona nagrody',
  },
  cert_icon_alt: {
    en: 'certs icon',
    pl: 'ikona certyfikaty',
  },
  membership: {
    en: 'MEMBERSHIPS',
    pl: 'Członkostwa',
  },
};
