import React from 'react';
import { Link } from 'gatsby';

import Navigation from 'src/components/Navigation/Navigation';
import Image from 'src/components/Image';

import styles from './Header.module.scss';

export default function Header() {
  return (
    <div className={styles.header}>
      <Link to="/" className={styles.logo} title="Allcon">
        <Image alt="Allcon.pl logo" filename="allcon-logo.png" />
      </Link>
      <Navigation />
    </div>
  );
}
