import companies from './companies';
import numbers from './numbers';
import awards from './awards';
import certificates from './certificates';
import footer from './footer';
import slides from './slides';
import allconDescription from './allconDescription';

const data = {
  companies,
  numbers,
  awards,
  certificates,
  footer,
  slides,
  allconDescription,
};

export const getDataForLang = (dataSetName, lang = 'pl') => {
  if (data && data[dataSetName] && data[dataSetName][lang]) {
    return data[dataSetName][lang];
  }

  console.error(`missing data for ${dataSetName} | ${lang}`);
};
