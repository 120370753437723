import React, { useState } from 'react';
import { Link } from 'gatsby';

import translate, { getLanguage } from 'src/translations';
import ContactBox from 'src/components/ContactBox';
import ScrollToButton from 'src/components/ScrollToButton';
import { useCompaniesData } from 'src/hooks';
import { localize } from 'src/utils';

import styles from './Navigation.module.scss';

const getLanguageLink = () => (getLanguage() === 'en' ? '' : 'en');
const getLanguageLabel = () => (getLanguage() === 'en' ? 'Polski' : 'English');

export default function Navigation() {
  const lang = getLanguage();
  const [isContactBoxVisible, setContactBoxVisible] = useState(false);
  const companies = localize(useCompaniesData(), [lang]);

  const openContactBox = () => {
    setContactBoxVisible(true);
  };

  const closeContactBox = () => {
    setContactBoxVisible(false);
  };

  const contactBoxClass = `${styles.contactBoxContainer} ${
    isContactBoxVisible ? styles.isActive : ''
  }`;

  return (
    <nav className={styles.navigation}>
      <div className={styles.item}>
        <ScrollToButton
          className={styles.label}
          label={translate('about')}
          selector="#section-numbers"
        />
      </div>
      <div
        className={styles.item}
        onMouseEnter={openContactBox}
        onMouseLeave={closeContactBox}
      >
        <button className={styles.contactButton} onClick={openContactBox}>
          <span className={styles.label}>{translate('contact')}</span>
        </button>
        <div className={contactBoxClass}>
          <ContactBox contactList={companies} closeCallback={closeContactBox} />
        </div>
      </div>
      <div className={`${styles.item} ${styles.languageSwitch}`}>
        <Link to={`/${getLanguageLink()}`}>
          <span className={styles.label}>{getLanguageLabel()}</span>
        </Link>
      </div>
    </nav>
  );
}
