import messages from './messages';

export function getLanguage() {
  const englishPathPrefix = '/en';

  if (typeof window === 'undefined') {
    return 'pl';
  }

  return window.location.pathname.indexOf(englishPathPrefix) > -1 ? 'en' : 'pl';
}

export default function translate(key) {
  const lang = getLanguage();
  const phrase = messages[key];

  if (!phrase) {
    throw new Error(`Missing translation's key: ${key}`);
  }

  const translatedMessage = phrase[lang];

  if (!translatedMessage) {
    throw new Error(`Missing translation for key: ${key} and lang: ${lang}`);
  }

  return translatedMessage;
}
