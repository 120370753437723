import React from 'react';
import { string } from 'prop-types';

export default function ScrollToButton({ className, label, selector }) {
  const onClick = e => {
    e.preventDefault();

    const element = document.querySelector(selector);

    if (!element) {
      return;
    }

    document.querySelector(selector).scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <button className={className} onClick={onClick}>
      {label}
    </button>
  );
}

ScrollToButton.propTypes = {
  className: string,
  label: string.isRequired,
  selector: string.isRequired,
};
