export default {
  pl: {
    text:
      'Od blisko 30 lat jesteśmy cenieni za jakość, niezawodność i wiarygodność wobec klientów i kontrahentów. Opierając się na wyznawanych wartościach i stabilnych fundamentach finansowych realizujemy projekty, które cieszą się uznaniem klientów i są nagradzane w prestiżowych konkursach.',
  },
  en: {
    text:
      'For almost 30 years we have been valued for our quality, reliability and credibility towards clients and contractors. Basing on our values and sound financial foundations we implement projects that are appreciated by customers and are awarded in prestigious competitions.',
  },
};
