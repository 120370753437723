import translate from 'src/translations';

export default {
  pl: {
    image: 'awardsIcon.png',
    imageAlt: translate('awards_icon_alt'),
    items: [
      {
        title: 'European Property Awards',
      },
      {
        title: 'Deweloper Roku',
      },
      {
        title: 'Przyjazny Deweloper',
      },
    ],
  },
  en: {
    image: 'awardsIcon.png',
    imageAlt: translate('awards_icon_alt'),
    items: [
      {
        title: 'European Property Awards',
      },
      {
        title: 'Developer of the Year',
      },
      {
        title: 'Friendly Developer',
      },
    ],
  },
};
