import React from 'react';
import Row from '../Row/Row';

import styles from './Footer.module.scss';

const Footer = ({ links }) => {
  return (
    <div className={styles.footer}>
      <Row>
        <div className={styles.container}>
          {links.map((columnData, columnId) => {
            return (
              <div className={styles.column} key={columnId}>
                <h4 className={styles.title}>
                  <span
                    style={{
                      borderColor: columnData.color,
                    }}
                  >
                    {columnData.title}
                  </span>
                </h4>
                <ul className={styles.list}>
                  {columnData.items.map((link, linkId) => {
                    const props = {
                      ...(link.href && {
                        href: link.href,
                        target: '_blank',
                        rel: 'noopener noreferrer',
                      }),
                    };
                    return (
                      <li className={styles.listItem} key={linkId}>
                        <a {...props}>{link.title}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </Row>
    </div>
  );
};

export default Footer;
