import React from 'react';
import styles from './ContactBox.module.scss';
import translate from 'src/translations';

export default function ContactBox({ contactList, closeCallback }) {
  return (
    <>
      <button className={styles.closeButton} onClick={closeCallback}>
        X
      </button>
      <div className={styles.container}>
        {contactList.map(({ node: { phone, email, contactUrl, name } }, id) => (
          <div className={styles.item} key={id}>
            <h5 className={styles.title}>{name} Sp. z o. o.</h5>
            <p>
              {phone && (
                <>
                  <span>{phone}</span>
                  <br />
                </>
              )}
              {email && (
                <>
                  <a href={`mailto: ${email}`}>{email}</a> <br />
                </>
              )}
              {contactUrl && (
                <a
                  href={contactUrl}
                  className={styles.more}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {translate('more')} →
                </a>
              )}
            </p>
          </div>
        ))}
      </div>
    </>
  );
}
