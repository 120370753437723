import tarasyImg from '../images/tarasy.jpg';
import badgeImg from '../images/badge.png';
import biuroSprzedazyImg from '../images/biuro-sprzedazy.jpg';

export default {
  pl: {
    items: [
      {
        key: 1,
        title: 'NAGRODA EUROPEAN PROPERTY AWARDS DLA TARASÓW BAŁTYKU',
        text:
          'Tarasy Bałtyku, nasz flagowy apartamentowiec realizowany w Gdańsku, zdobył międzynarodową nagrodę FIVE STAR w kategorii Best Residential High–rise Development Poland w ramach prestiżowego konkursu European Property Awards 2019-2020. Tym samym projekt znalazł się wśród najlepszych inwestycji w Europie.',
        image: tarasyImg,
        badge: badgeImg,
        link:
          'https://www.allcon.pl/mieszkania/apartamenty/tarasy_baltyku?utm_source=allconpl&utm_medium=epa',
      },
      {
        key: 1,
        title: 'BIURA SPRZEDAŻY OTWARTE - ZAPRASZAMY DO UMÓWIENIA SPOTKANIA',
        text:
          'Miło nam poinformować, że Biura Sprzedaży w Gdyni (ul. Łużycka 6A) i Gdańsku (al. Jana Pawła II 3C oraz al. Grunwaldzka 102/9) są już otwarte. By zapewnić komfort i bezpieczeństwo Klientom prosimy o kontakt telefoniczny lub mailowy przed umówieniem spotkania w biurze, na budowie lub na terenie gotowej inwestycji.',
        image: biuroSprzedazyImg,
        link:
          'https://www.allcon.pl/mieszkania/apartamenty/tarasy_baltyku?utm_source=allconpl&utm_medium=epa',
      },
    ],
  },
  en: {
    items: [
      {
        key: 1,
        title: 'TARASY BAŁTYKU - EUROPEAN PROPERTY AWARDS 2019-2020 WINNER',
        text:
          'Tarasy Baltyku, our flagship project being built in Gdansk, has received the FIVE STAR award in the Best Residential High–rise Development Poland category in the prestigious European Property Awards 2019-2020 contest. Thereby, the project has been placed amongst the very best developments in Europe.',
        image: tarasyImg,
        badge: badgeImg,
        link:
          'https://www.allcon.pl/mieszkania/apartamenty/tarasy_baltyku?utm_source=allconpl&utm_medium=epa',
      },
    ],
  },
};
