import { useStaticQuery, graphql } from 'gatsby';

const useCompaniesData = () => {
  const { allSanitySlide } = useStaticQuery(
    graphql`
      query SlidesQuery {
        allSanitySlide(sort: { fields: order, order: ASC }) {
          edges {
            node {
              title {
                _type
                pl
                en
              }
              content {
                _type
                pl
                en
              }
              url
              image {
                asset {
                  fluid(maxWidth: 900) {
                    src
                  }
                }
              }
              ribbon {
                asset {
                  fluid(maxWidth: 500) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return allSanitySlide.edges;
};

export default useCompaniesData;
