export default {
  pl: [
    {
      title: 'DEWELOPER',
      color: '#0087cd',
      items: [
        {
          title: 'Mieszkania i apartamenty w Gdańsku',
          href:
            'https://www.allcon.pl/mieszkania/apartamenty/nowe-mieszkania/gdansk',
        },
        {
          title: 'Domy jednorodzinne w Gdańsku',
          href: 'https://www.allcon.pl/mieszkania/apartamenty/linea_domy',
        },
        {
          title: 'Mieszkania i apartamenty w Gdyni',
          href:
            'https://www.allcon.pl/mieszkania/apartamenty/nowe-mieszkania/gdynia',
        },
        {
          title: 'Apartamenty inwestycyjne nad morzem',
          href:
            'https://www.allcon.pl/mieszkania/apartamenty/nexo_inwestycyjne_apartamenty?utm_source=allconpl',
        },
      ],
    },
    {
      title: 'WYNAJEM NIERUCHOMOŚCI',
      color: '#0087cd',
      items: [
        {
          title: 'Lokale usługowe w Trójmieście',
          href: 'https://www.allcon.pl/nieruchomosci/',
        },
        {
          title: 'Lokale usługowe w Gdańsku',
          href: 'https://www.allcon.pl/nieruchomosci/inwestycje/atrium-oliva/',
        },
        {
          title: 'Lokale usługowe w Gdyni',
          href: 'https://www.allcon.pl/nieruchomosci/inwestycje/nowa-dabrowa/ ',
        },
        {
          title: 'Lokale usługowe na sprzedaż',
          href:
            'https://www.allcon.pl/nieruchomosci/inwestycje/tarasy-baltyku/',
        },
      ],
    },
    {
      title: 'GENERALNY WYKONAWCA',
      color: '#0087cd',
      items: [
        {
          title: 'Generalne Wykonawstwo',
          href: 'https://www.allcon.pl/budownictwo/',
        },
        {
          title: 'Zaprojektuj i wybuduj',
          href: 'https://www.allcon.pl/budownictwo/pl/formuly_wspolpracy',
        },
        {
          title: 'Inwestycje w realizacji',
          href: 'https://www.allcon.pl/budownictwo/pl/realizacje',
        },
        {
          title: 'Zrealizowane inwestycje',
          href: 'https://www.allcon.pl/budownictwo/pl/realizacje',
        },
      ],
    },
  ],
  en: [
    {
      title: 'Allcon Osiedla',
      color: '#0087cd',
      items: [
        {
          title: 'Apartments Gdańsk',
          href:
            'https://www.allcon.pl/mieszkania/apartamenty/nowe-mieszkania/gdansk?utm_source=allconpl',
        },
        {
          title: 'Houses Gdańsk',
          href:
            'https://www.allcon.pl/mieszkania/apartamenty/domy_malczewskiego?utm_source=allconpl',
        },
        {
          title: 'Apartments Gdynia',
          href:
            'https://www.allcon.pl/mieszkania/apartamenty/nowe-mieszkania/gdynia?utm_source=allconpl',
        },
        {
          title: 'Apartments Puck',
          href:
            'https://www.allcon.pl/mieszkania/apartamenty/nexo?utm_source=allconpl',
        },
      ],
    },
    {
      title: 'Allcon Nieruchomości',
      color: '#0087cd',
      items: [
        {
          title: 'Available premises in Gdansk',
          href:
            'https://www.allcon.pl/nieruchomosci/en/inwestycje/tarasy-baltyku-2/?utm_source=allconpl',
        },
        {
          title: 'Planned premises in Gdansk',
          href:
            'https://www.allcon.pl/nieruchomosci/en/inwestycje/mlyny-gdanskie-3/?utm_source=allconpl',
        },
        {
          title: 'Planned premises in Gdynia',
          href:
            'https://www.allcon.pl/nieruchomosci/en/inwestycje/sceneria-parkowa-4/?utm_source=allconpl',
        },
        {
          title: 'Premises in Tricity',
          href: 'https://www.allcon.pl/nieruchomosci/en/?utm_source=allconpl',
        },
      ],
    },
    {
      title: 'Allcon Budownictwo',
      color: '#0087cd',
      items: [
        {
          title: 'General Contractor',
          href: 'https://www.allcon.pl/budownictwo/en',
        },
        {
          title: 'Design and build',
          href: 'https://www.allcon.pl/budownictwo/en/formuly_wspolpracy',
        },
        {
          title: 'Completed developments',
          href: 'https://www.allcon.pl/budownictwo/en/realizacje',
        },
        {
          title: 'Developments in progress',
          href: 'https://www.allcon.pl/budownictwo/en/realizacje',
        },
      ],
    },
  ],
};
