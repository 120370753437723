import translate from 'src/translations';

export default {
  pl: {
    image: 'certificatesIcon.png',
    imageAlt: translate('cert_icon_alt'),
    items: [
      {
        title: 'Certyfikat Rzetelna Firma',
      },
      {
        title: 'Certyfikat Wiarygodności Biznesowej',
      },
      {
        title: 'Budowa Roku',
      },
    ],
  },
  en: {
    image: 'certificatesIcon.png',
    imageAlt: translate('cert_icon_alt'),
    items: [
      {
        title: 'Reliable Company Certificate',
      },
      {
        title: 'Certificate of Business Credibility',
      },
      {
        title: 'Construction of the Year',
      },
    ],
  },
};
