import { useStaticQuery, graphql } from 'gatsby';

const useCompaniesData = () => {
  const { allSanityAllconNumber } = useStaticQuery(
    graphql`
      query AllconNumberQuery {
        allSanityAllconNumber(sort: { fields: order, order: ASC }) {
          edges {
            node {
              value
              unit {
                _type
                pl
                en
              }
              description {
                _type
                pl
                en
              }
              prefix {
                _type
                pl
                en
              }
            }
          }
        }
      }
    `
  );
  return allSanityAllconNumber.edges;
};

export default useCompaniesData;
