import { useStaticQuery, graphql } from 'gatsby';

const useCompaniesData = () => {
  const { allSanityCompany } = useStaticQuery(
    graphql`
      query CompaniesQuery {
        allSanityCompany(sort: { fields: order, order: ASC }) {
          edges {
            node {
              name
              phone
              email
              contactUrl
              url
              title {
                _type
                pl
                en
              }
              buttonLabel {
                _type
                pl
                en
              }
              primaryColor {
                hex
              }
              image {
                asset {
                  fluid(maxWidth: 700) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return allSanityCompany.edges;
};

export default useCompaniesData;
