import imgApartments from '../images/tile-mieszkania.jpg';
import imgRealEstate from '../images/tile-lokale.jpg';
import imgConstruction from '../images/tile-budownictwo.jpg';

export default {
  pl: {
    items: [
      {
        key: 1,
        title1: 'Apartamenty,',
        title2: 'Mieszkania, Domy',
        subtitle: 'Allcon Osiedla',
        buttonLabel: 'Poznaj ofertę',
        image: imgApartments,
        color: '#0087cd',
        href:
          'https://www.allcon.pl/mieszkania/apartamenty?utm_source=allconpl',
      },
      {
        key: 2,
        title1: 'Lokale',
        title2: 'użytkowe',
        subtitle: 'Allcon Nieruchomości',
        buttonLabel: 'Dowiedz się więcej',
        image: imgRealEstate,
        color: '#0087cd',
        href: 'https://www.allcon.pl/nieruchomosci/',
      },
      {
        key: 3,
        title1: 'Generalny',
        title2: 'wykonawca',
        subtitle: 'Allcon Budownictwo',
        buttonLabel: 'Zobacz realizacje',
        image: imgConstruction,
        color: '#0087cd',
        href: 'https://www.allcon.pl/budownictwo',
      },
    ],
  },
  en: {
    items: [
      {
        key: 1,
        title1: 'Apartments',
        title2: 'and houses',
        subtitle: 'Allcon Osiedla',
        buttonLabel: 'Discover our offer',
        image: imgApartments,
        color: '#0087cd',
        href:
          'https://www.allcon.pl/mieszkania/apartamenty?utm_source=allconpl',
      },
      {
        key: 2,
        title1: 'Commercial',
        title2: 'premises',
        subtitle: 'Allcon Nieruchomości',
        buttonLabel: 'Learn more',
        image: imgRealEstate,
        color: '#0087cd',
        href: 'https://www.allcon.pl/nieruchomosci/en/',
      },
      {
        key: 3,
        title1: 'General',
        title2: 'contractor',
        subtitle: 'Allcon Budownictwo',
        buttonLabel: 'See our work',
        image: imgConstruction,
        color: '#0087cd',
        href: 'https://www.allcon./en',
      },
    ],
  },
};
