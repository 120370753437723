export default {
  pl: {
    title: 'ALLCON W LICZBACH',
    items: [
      {
        number: 30,
        description: 'lat na rynku',
        unit: 'blisko',
        position: 'top',
      },
      {
        number: 4000,
        description: 'wybudowanych i sprzedanych mieszkań',
      },
      {
        number: 150,
        description: 'zrealizowanych inwestycji',
      },
      {
        number: 893,
        description: 'oddanych do użytku',
        unit: 'tys. m<sup>2</sup>',
      },
    ],
  },
  en: {
    title: 'ABOUT ALLCON GROUP',
    items: [
      {
        number: 30,
        description: 'years on the market',
        unit: 'almost',
        position: 'top',
      },
      {
        number: 4000,
        description: 'apartments built and sold',
      },
      {
        number: 150,
        description: 'completed investments',
      },
      {
        number: 893,
        description: 'put into service',
        unit: 'thous. m<sup>2</sup>',
      },
    ],
  },
};
